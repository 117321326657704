
.file-status-card {
    display: inline-block;
    width: calc(100% - 60px);    
    height: 3vw;
    background: white;
    border-radius: 10px;
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 6px;
    text-align: left;
    vertical-align: middle;
    box-shadow: 11px -11px 8px 0px #00000005;
    margin-top: 6px;
    position: relative;
    cursor: pointer;
}

.file-status-card.selected {
    background-color: rgb(191 191 191 / 40%);
}

.file-status-card-icon {
    display: inline-block;
    overflow: hidden;
    position: relative;
    bottom: 10px;
}

.file-status-card-icon svg {
    fill: #484F59
}

.file-status-card-datetime-container {
    width: 35%;
    display: inline-block;
    bottom: 5px;
    position: relative;

}
.file-status-card-datetime {
    height: 2vw;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Gilroy-Bold;
    font-size: 1.1vw;
    color: #484F59;
    padding-left: 15px;
    position: relative;
    bottom: 10px;
}

.file-status-card-filename-container {
    height: 3.6vw;
    width: 40%;
    display: inline-block;
    bottom: 0px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
}
.file-status-card-filename {
    height: 1.5vw;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Gilroy-Bold;
    font-size: 1.1vw;
    color: #484F59;
    padding-left: 15px;    
}

.file-status-card  svg{
    width: 2.8vw;
    height: 2.8vw;
}

.file-status-card-result {
    font-family: Gilroy-Medium;
    font-size: 1vw;
    padding-left: 15px;
}

.file-status-card-result.success {
    color: #65CB70
}

.file-status-card-result.error {
    color: #EE0924
}

.file-status-card-result.pending {
    color: #ee7409
}


.file-status-card-icons-container {
    display: inline-block;
    height: 50px;
    width: 16%;
    text-align: right;
    position: relative;
    bottom: 4px;
}

.file-status-card-icons-container svg {
    cursor: pointer;
    width: 1.4vw;
    height: 1.7vw;
    position: relative;
    bottom: 13px;
}

.file-status-card-icons-container svg path {
    fill: #34415A;
}

.success-result {
    bottom: 3px;
    position: relative;
    padding-right: 10px;
}

.success-result svg {
    width: 15px;
}

.success-result svg path {
    fill: #65CB70;
}

.error-result {
    bottom: 3px;
    position: relative;
    padding-right: 10px;
}

.error-result svg {
    width: 15px;
}

.error-result svg path {
    fill: #EE0924;
}

.pending-result {
    bottom: 3px;
    position: relative;
    padding-right: 10px;
}

.pending-result svg {
    width: 15px;
}

.pending-result svg path {
    fill: #ee7409;
}

.float-error-icon {
    position: absolute;
    left: -20px;
    top: -10px;
    z-index: 20;
}

.float-error-icon svg {
    height: 32px;
    width: 32px;
}



.static-card {
    width: 200px;
    min-height: 83px;
    display: inline-block;
    border-radius: 10px;
    text-transform: uppercase;
    font-family: 'Gilroy-Bold';
    color: #34415A;
    padding: 14px 14px 5px 14px;
    vertical-align: top;
    text-align: left;

}
.static-card.success {
    background-color: #C4CED9;
    margin-right: 40px;
}
.static-card.error {
    background-color: #dccbd2;
}

.static-card>.card-title {
    font-size: 18px;
    display: block;
}

.static-card>.card-result {
    font-size: 49px;
    display: inline-block;
}


svg.error-csv-download-icon {
    color: var(--primary-color);
    width: 1.7vw;
    height: 1.7vw;
    margin-left: 5px;
    cursor: pointer;
}

.warningIcon svg {
    width: 1.7vw;
    height: 2vw;
    position: relative;
    right: 7px;
    bottom: 13px;
}

.warningIcon svg path {
    fill: #ee7409;
}
