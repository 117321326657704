.table-container-load {
    padding-top: 40px;
    height: 260px;
    overflow: hidden;
}

.assign-stock-grids-container {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    width: 87%;
    position: relative;
}

.gridContainer {
    display: inline-block;
    width: 100%;
    overflow-x: auto;
    text-align: center;
    height: calc(100vh - 265px);
}

.side-grid {
    display: inline-flex;
    width: 49%;
    overflow: hidden;
    height: 280px;
}

.side-grid.new-stock-side {
    padding-left: 20px;
    text-align: right;
    width: calc(50% - 30px);
}

.staticCardRate {
    min-width: 200px;
    min-height: 86px;
    display: inline-block;
    border-radius: 10px;
    text-transform: uppercase;
    font-family: 'Gilroy';
    font-weight: 700;
    color: #34415A;
    padding: 14px 14px 5px 14px;
    vertical-align: top;
    text-align: left;
    border: 2px solid rgba(128, 128, 128, 0.282);
    margin-right: 20px;
}

.desc-card-assign-stock {
    background-color:  rgba(203, 191, 217, .6);
    width: calc(34% - 20px);
}

.info-card-interval {
    width: calc(40% - 20px);
    position: relative;
    overflow: auto;
    max-height: 115px;
}

.info-card-interval div {
    position: relative;
    left: 8px;
    width: calc(100% - 20px);
    margin-top: 10px;
    text-transform: none;
}


.static-card-assign-stock .card-title {
    font-size: 18px;
    display: block;
    margin-bottom: 5px;
}

.static-card-assign-stock .card-desc {
    font-family: 'Gilroy-Bold';
    font-size: 25px;
    display: block;
}

.assign-table-title {
    display: inline-block;
    width: 50%;
    text-align: center;
    text-transform: uppercase;
    color: var(--primary-color);
}


.assign-stock-table table {
    border-spacing: 1px !important;
    background-color: white !important;
    min-width: -moz-fit-content !important;
    min-width: fit-content !important;
}

.assign-stock-table thead {
    position: sticky;
    top: 0;
    z-index: 1001;
    height: 40px !important;
    width: 100% !important;
    margin-bottom: 0px !important;
    box-shadow: -5px 12px 8px 0px rgba(0, 0, 0, 0.02) !important;
    background-color: #f5f6fa !important;
}

.assign-stock-table tbody {
    background-color: white !important;
    position: unset !important;
    width: calc(100% - 14px) !important;
}

.MuiPaper-rounded.newsan-table.assign-stock-table thead th {
    font-family: 'Gilroy-Bold';
    color: var(--secondary-color);
}


.assign-stock-header-container {
    margin-top: 20px;
    margin-bottom: 30px;
}

.assign-stock-header-title {
    display: block;
    width: 100%;
    font-family: 'Gilroy-Bold';
    font-size: 49px;
    color: var(--primary-color);
    margin-bottom: 20px;
    margin-left: -17vw;
}

.assing-stock-character-container {
    display: block;
    position: absolute;
    bottom: -25px;
    right: -150px;
}

.assing-stock-character-container svg {
    height: 320px;
    width: 160px;
}


.static-card-assign-stock.info-card-assign-stock .card-desc {
    font-family: 'Gilroy-Bold';
    font-size: 14px;
    display: block;
}

.MuiPaper-rounded.newsan-table.assign-stock-table .custom-element {
    width: 75%;
    display: inline-block;
}

.MuiPaper-rounded.newsan-table.assign-stock-table .custom-element input {
    background-color: transparent;
    border: 1px solid rgba(196, 206, 217, 1);
    color: rgba(196, 206, 217, 1);
    text-align: center;
    padding-left: 0px;
    font-size: 0.875rem;;
}

.MuiPaper-rounded.newsan-table.assign-stock-table tbody tr td:last-of-type > span {
    width: 100%;
    margin-right: 0;
}

.MuiPaper-rounded.newsan-table tbody tr:first-of-type td {
    padding-top: 0px;
}

.card-refres-icon {
    padding: 3px;
    position: absolute;
    top: 10px;
    background-color: var(--primary-color);
    color: white;
    right: 10px;
    border-radius: 10px;
    width: 25px;
    height: 25px;
    text-align: center;
    padding-top: 6px;
    cursor: pointer;
}

.buttonAddReserve {
    position: absolute;
    left: 0px;
    top: 10px;
}

.buttonAddReserve svg {
    width: 55px;    
    height: 55px;
    cursor: pointer;
}

.buttonAddReserve span {
    display: inline-block;
}

.buttonAddReserve span.text {
    width: 50px;
    position: absolute;
    font-size: 14px;
    top: 3px;
    cursor: pointer;
}

.okBtn svg path {
    fill: var(--primary-color);
}

.cancelBtn svg path {
    fill: var(--secondary-color);
}

.checkButton {
    position: relative;
    height: 15px;
    width: 15px;
    display: inline-block;
    padding: 3px;
    background: var(--primary-color);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 5px;
}

.checkButton svg {
    height: 15px;
    width: 15px;
}

.customElement input {
    background-color: #f5f6fa;
    border-radius: 10px;
    border-width: 0;
    font-family: Gilroy-Medium;
    font-size: 13px;
    height: 31.5px;
    padding-left: 10px;
    width: calc(100% - 50px);
}

span.sliderCss {
    color: var(--primary-color);
    width: 97%;
}

span.sliderCss span:nth-child(4) {
    font-family: 'Gilroy';
    font-weight: 700;
    color: rgba(0, 0, 0, 0.6);
}

span.sliderCss span:nth-child(6) {
    font-family: 'Gilroy';
    font-weight: 700;
    color: rgba(0, 0, 0, 0.6);
}

.sliderLimit {
    color: var(--primary-color);
    position: relative;
    right: 2px;
    bottom: 10px;
    font-size: 20px;
}

.titleType {
    color: #34415A;
    /* Button */
    font-family: Gilroy;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 21.6px */
    text-transform: uppercase;
}

.MuiSlider-thumb::after {
    width: 38px;
}

.character {
    position: relative;
}

.character svg {
    height: 256px;
    position: absolute;
    left: 32px;
    width: 325px;
    top: -110px;
    z-index: 1000;
}

.checkboxOpt {
    cursor: pointer;
}

.checkboxOpt > rect {
    fill:transparent;
}
.checkboxOpt.checked > rect {
    fill: var(--primary-color);
}

.checksContainer {
    margin-top: 2%;
    text-transform: none;
    color: rgba(0, 0, 0, 0.6);
}

.checksContainer > span {
    padding-right: 15px;
}

.checksContainer svg {
    position: relative;
    top: 8px;
    padding-right: 10px;
}

.textWithCopy{
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    z-index: 999;
}

.helpIcon{
    cursor: pointer;
    top: 4px;
}

.helpIcon svg{
    fill: var(--primary-color)
}

.helpModal{
    position: absolute;
    top: 50%;
    left: 50%;
    height: fit-content;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 24px;
    border-radius: 10px;
    padding: 24px;
    border: 3px solid var(--secondary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.helpModal p{
    line-height: 120%;
    text-align: justify;
}

.helpModal p div.sangria{
    text-indent: 4em;
}

.helpModal strong{
    color: var(--primary-color);
    line-height: 120%;
}

.helpModal button{
    background-color: var(--secondary-color);
    color: white;
    margin-top: 24px;
}
.helpModal button:hover{
    background-color: var(--secondary-color) ;
    opacity: 80%;
    color: white;
}

.hourRed{
    background-color: #721C31;
    border-radius: 5px;
    border: 1px solid #721C31;
    color: white;
    padding: 4px;
    width: 83%;
}

.hourGreen{
    background-color: #D8DB85;
    border-radius: 5px;
    border: 1px solid #D8DB85;
    padding: 4px;
    width: 83%;
}

.salesRatePage{
    height: auto;
}

@media (max-width: 1366px) {
    .salesRatePage{
      height: calc(125vh - 20px);
    }

    .gridContainer {
        height: calc(125vh - 265px);
    }
}