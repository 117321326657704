.container-abm-page {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 2vw;
    border: 2px solid rgba(128, 128, 128, 0.282);
    border-radius: 10px;
}

.massive-section {
    display: flex;
    flex-direction: row;
    padding: 2vw;
    border: 2px solid rgba(128, 128, 128, 0.282);
    border-radius: 10px;
}

.table-templates-container {
    padding-top: 15px;
    height: calc(100% - 70px);
    width: calc(100% - 24px);
    margin-left: 10px;
}

.dataTableStock table{
    min-width: auto;
}

.refreshIconTitle svg {
position: relative;
cursor: pointer;
cursor: pointer;
fill: var(--primary-color);
}

p.titleClass{
    font-size: 1.5rem;
    margin-bottom: 12px;
    font-weight: bold;
    position: relative;
    top: -8px;
}

p.productDescription{
    font-size: 1.5rem;
    color: var(--primary-color);
    margin: 12px 10px;
    align-self: center;
}

button.button-newsan-red{
    height: 41px;
    padding: 0px 15px 0px 15px;
    margin-right: 10px;
    border-radius: 10px;
    color: white;
    background-color: var(--primary-color);
    font-family: 'Gilroy-Medium';
    text-transform: none;
    font-size: 15px;
    border: 1px solid var(--primary-color);
    box-shadow: none;
    position: relative;
    width: 24%;
    gap: 6px;
    white-space: nowrap;
}

button.button-newsan-red:hover {
    background-color: var(--primary-color);
}

button.button-newsan-grey{
    height: 41px;
    padding: 0px 15px 0px 15px;
    margin-right: 20px;
    border-radius: 10px;
    color: white;
    background-color: var(--secondary-color);
    font-family: 'Gilroy-Medium';
    text-transform: none;
    font-size: 15px;
    border: 1px solid var(--secondary-color);
    box-shadow: none;
    width: 24%;
    gap: 6px;
}

button.button-newsan-grey:hover {
    background-color: var(--secondary-color);
}

.searchIcon {
    position: relative;
    bottom: -2px;
    padding-right: 8px;
}

span.searchIcon svg{
    width: 18px;
    height: 18px;
}

span.searchIcon svg path{
    fill: white
}

.containers{
    display: flex;
    flex-direction: column;
    width: 60%;
}

.character {
    position: relative;
}

.character svg {
    height: 568px;
    position: absolute;
    left: 16px;
    width: 560px;
    top: 0px;
    z-index: 1000;
}

@media (max-width: 1366px) {
    .availableStockPage{
        height: calc(124vh - 20px);
    }
}