#form-file-upload {
  height: 100%;
  width: 100%;
  text-align: center;
  position: relative;
  border-style: dashed;
  border-radius: 35px;
  border-color: var(--primary-color);
  background: white;
}

#input-file-upload {
  display: none;
}


#label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: var(--primary-color);
}

#label-file-upload.drag-active {
  background-color: #ffffff;
  opacity: .2;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.drop-legend {
    font-family: Gilroy-Bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #484F59;
    text-transform: uppercase;
}

.or-legend {
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0.005em;
    text-align: center;
    color: #B3B3B3
}

.icon-add > svg {
    width: 70px;
    height: 85px;
}

.background-dashed svg {
  height: 100%;
  width: 100%;
}