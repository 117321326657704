
tr.customRow td span {
    position: relative;
    top: 7.25px;
}
tr.customRow td:last-of-type span{
    position: relative;
    top: 7.25px;
    padding-bottom: 12px !important;
}

.textWithCopy{
    display: flex;
    bottom: 10px;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
}

tr.customRow td span.restartIconCont {
    position: relative;
    bottom: 10px;
    top: unset;
}

.restartIcon {
    position: relative;
    bottom: 1px;
    cursor: pointer;
}

.restartIcon svg {
    width: 20px;
    left: 8px;
    position: relative;

}

.refreshIconTitle svg {
    position: relative;
    cursor: pointer;
    cursor: pointer;
    fill: var(--primary-color);
  }

.container-abm-page{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    height: 95vh;
}

.table-templates-container {
    padding-top: 15px;
    height: calc(100% - 70px);
    overflow: hidden;
    display: inline-flex;   
    min-width: calc(100% - 30px);
    flex-direction: column;
}

.refreshIconModal > span{
    top: 4px;
}

.panel-filters {
    height: 80px;
    padding: 1vw 0vw 1vw 1vw;
    border: 2px solid rgba(128, 128, 128, 0.282);
    border-radius: 10px;
}

.panel-filters.expanded {
    height: 160px;
}

.filter-row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.filter-box {
    width: 14%;
    height: 80px;
    display: inline-block;
    vertical-align: bottom;
}

.filter-box label {
    font-family: 'Gilroy-Bold';
    font-size: 15px;
    color: #34415A;
    display: block;
}

.filter-box input {
    border-radius: 10px;
    background-color: #F5F6FA;
    border-width: 0px;
    height: 31.5px;
    margin-top: 9px;
    width: calc(100% - 25px);
    padding-left: 10px;
    font-family: Gilroy-Medium;
    font-size: 13px;
    color: black
}

.filter-box input::placeholder {
    font-family: Gilroy-Medium;
    color: #C4CED9;
    font-size: 13px;
}

.filter-box input:focus-visible {
    outline: none;
}

.no-selection {
    color: #C4CED9;
    font: unset;
    font-size: 13px;
    font-family: 'Gilroy-Medium';
}

div.select-filter {
    min-width: calc(100% - 15px);
    max-width: calc(100% - 15px);
    margin-top: 9px;;
    position: relative;
}

div.select-filter fieldset.MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}

div.select-filter > div {
    background: #F5F6FA;
    border-radius: 10px;
    height: 34px;
    font-size: 14px;
    font-family: 'Gilroy-Medium';
}

div.select-filter > div > div{
    width: 65%;
}

.select-option {
    font-size: 15px;
    font-family: 'Gilroy-Medium';
}

.collapsible-box {
    height: auto;
    display: inline-block;
    width: 4%;
    text-align: right;
    position: relative;
    bottom: 50px;
}

.collapsible-box svg {
    position: relative;
    top: 35px;
    cursor: pointer;
}

.custom-menu-item {
    font-size: 10px;
    padding: 6px 10px;
}

.searchIcon {
    position: relative;
    bottom: -2px;
    padding-right: 8px;
}

span.searchIcon svg{
    width: 18px;
    height: 18px;
}

span.searchIcon svg path{
    fill: white
}

.errorModal{
    position: absolute;
    top: 50%;
    left: 50%;
    height: fit-content;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 24px;
    border-radius: 10px;
    padding: 24px;
    border: 3px solid var(--secondary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.errorModal button{
    background-color: var(--secondary-color);
    color: white;
    margin-top: 24px;
}
.errorModal button:hover{
    background-color: var(--secondary-color) ;
    opacity: 80%;
    color: white;
}

.clearButton{
    cursor: pointer;
    position: absolute;
    /* top: 1px; */
    bottom: 26px;
    left: 120px;
}

.check-label{
    font-size: 15px;
    height: 20px;
    display: inline-block;
    position: relative;
    top: -4px;
    left: 2px;
}

@media (max-width: 1366px) {
    .auditReportPage{
        height: calc(124vh - 20px);
    }
}