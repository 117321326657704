.container{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.contentColumn{
    width: 85%;
    display: flex;
    flex-direction: column;
    color: var(--secondary-color);
    margin-left: 2%;
    margin-right: 2%;
    font-size: 13px;
}

.titleContainer{
    text-wrap: wrap;
}

.describeContainer {
    font-size: 13px;
    font-weight: bold;
    line-height: 1.5;
    height: calc(1.5em * 2);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.dateContainer{
    font-size: 11px;
    font-weight: 200;
    margin-top: 2%;
}

.deleteIcon{
    align-content: center;
    cursor: pointer;
}

.iconRed path{
    fill: #d90337;
}

.iconYellow path{
    fill: rgb(255, 149, 0);
}

.iconBlue path{
    fill: rgb(0, 178, 27);
}