.backgroundImage {
    height: 100%;
    background: #F5F6FA;
    text-align: center;
    position: relative;
    overflow: auto;
}

.backgroundImage > div {
    width: 100%;
    height: 100%;
}

.homeTitle {
    color: var(--secondary-color);
    font-family: Mont;
    font-size: 3vw;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    display: block;
}

.homeCard {
    border-radius: 1.5vw;
    background: var(--Blanco, #FFF);
    box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.10);
    display: inline-block;
    width: 20vw;
    height: 13vw;
    text-align: center;
    margin-right: 1vw;
    margin-bottom: 2vw;
}
.imageContainer {
    display: block;
    width: 100%;
    text-align: center;
}

.logoSize {
    width: 16vw !important;
    height: 6vw !important;
}
.stepperContainerForm {
    width: 100%;
    display: inline-block;
    height: 3vw;
}

.stepperContainer {
    display: inline-block;
    width: 44vw;
    height: 31vw;
    margin-top: 2vw;
    text-align: left;
    background: white;
    padding: 3vw;
    border-radius: 1.3vw;
    box-shadow: 0px 0px 2vw 0px rgba(0, 0, 0, 0.10);
    padding-top: 2vw;
    position: relative;
}

.labelStep span {
    font-size: 1.2vw;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
}

.labelStep.active span {
    color: var(--primary-color)!important;
}

.labelStep span span.MuiStepLabel-alternativeLabel {
    margin-top: 1vw;
}

.stepperStep {
    height: 3vw;
    width: 3vw;
}

.stepperStep svg {
    height: 3vw;
    width: 3vw;
}

.stepperStep svg text {
    fill: var(--secondary-color);
    font-family: 'mont';
    font-weight: 700;
}

.stepperStep svg circle {
    fill: none;
    stroke: var(--secondary-color);
}

svg.stepComplete text {
    fill: white;
    font-family: 'mont';
    font-weight: 700;
}

svg.stepComplete circle{
    fill: var(--primary-color);
    stroke: var(--primary-color);
}

svg.stepActive text {
    fill: var(--primary-color);
    font-family: 'mont';
    font-weight: 700;
}

svg.stepActive circle{
    fill: none;
    stroke: var(--primary-color);
}

.stepperStep > div {
    top: 1.5vw;
    left: calc(-50% + 3vw);
    right: calc(50% + 3vw);
}

.stepperStep > div > span {
    border-block-width: 2px;
    border-color: #403F41;
}

.stepTitle {
    width: 49vw;
    height: 3.2vw;
    display: inline-block;
    background: var(--primary-color);
    text-align: center;
    border-radius: 1vw;
    position: relative;
    bottom: 1.5vw;
}

.title {
    font-size: 2.1vw;
    margin-top: 0.4vw;
    display: inline-block;
    color: #FFF;
    font-family: Mont;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.iconTitle svg {
    position: absolute;
    left: 3vw;
    height: 3vw;
    width: 3.3vw;
    top: 0.2vw;
}

.iconTitle svg path {
    fill: white;
}

.backButton {
    width: 6vw;
    height: 3.3vw;
    display: inline-block;
    margin-right: 1vw;
    border-radius: 1vw;
    background: white;
    cursor: pointer;
    position: relative;
    bottom: 0.5vw;
}

.backButton svg {
    position: relative;
    bottom: 0.1vw;
    right: 0.5vw;
    width: 5vw;
    height: 3.5vw;
    color: var(--primary-color);
}

.headerStepper {
    width: 74.5%;
    display: block;
    text-align: right;
    height: 4vw;
}

.stepperLegend {
    color: #403F41;
    text-align: center;
    font-family: Mont;
    font-size: 1.4vw;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-top: 0;
}


.formButtonBack:disabled {
    border: 2px solid #D9D9D9;
    color: #D9D9D9;
}

.formButtonBack {
    border-radius: 0.8vw;
    border: 2px solid var(--primary-color);
    background: var(--Blanco, #FFF);
    color: var(--primary-color);
    width: 11vw;
    font-family: 'mont';
    font-weight: 400;
    font-size: 1vw;
    margin-right: 3vw;
}

.formButtonNext {
    border-radius: 0.8vw;
    border: 2px solid var(--primary-color);
    color: var(--Blanco, #FFF);
    background: var(--primary-color);
    width: 11vw;
    font-family: 'mont';
    font-weight: 400;
    font-size: 1vw;
}

.formButtonNext:hover {
    color: var(--Blanco, #FFF);
    background: var(--primary-color);
    opacity: .9;

}

.formContainer {
    display: inline-block;
    width: 100%;
    text-align: left;
    margin-left: 0.5vw;
    margin-top: 4vw;
    max-height: 24vw;
    overflow-y: auto;
    margin-bottom: 1vw;
}

.fieldContainer {
    display: inline-table;
    width: calc(100% - 4vw);
    padding-inline: 2vw;
    padding-bottom: 1vw;
    position: relative;
    z-index: 1002;
}

.fieldContainer label {
    color: #403F41;
    /* font-family: Archivo Narrow; */
    font-size: 1vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.fieldContainer input.formField {
    width: calc(100% - 2vw - 8px);
    border-radius: 5px;
    border: 1px solid var(--Rectangle, #D9D9D9);
    height: 2vw;
    font-size: 1vw;
    padding-left: 1vw;
    margin-top: 0.5vw;
}

.fieldContainer div.form-select-opt {
    width: calc(100% - 1vw - 5px);
    border-radius: 5px;
    height: 2vw;
    font-size: 1vw;
    margin-top: 0.5vw;
}

.fieldContainer input.formField.error {
  margin-bottom: 1vw;
}

.fieldContainer div.form-select-opt.error {
  padding-bottom: 1.5vw;
}



.fieldContainer label.required::after {
    content:" *";
    color:rgb(207 0 0);
}

.fieldContainer div {
    position: relative;
    padding-top: 0.1vw;
}


.mobileContainer {
    display: none;
}

.errorMsg {
    color: rgb(207 0 0);
    font-size: 1vw;
    position: absolute;
    bottom: -1.5vw;
    overflow: visible;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    width: 100%;
    left: 0;
    padding-bottom: 1vw;
}

.autocompleteContainer ~ .errorMsg {
    bottom: -1.7vw;
}

.autocompleteContainer {
    margin-top: 1vw;
    margin-bottom: 1vw;
}

.autocompleteContainer div {
    padding: 0 !important;
    height: 2.3vw;
    margin-top: -0.5vw;
}

.autocompleteContainer input {
    padding: 3px 4px 7.5px 5px !important;
    position: relative;
    left: 0.6vw;
    font-size: 1.2vw;
}

label.radioOpt {
    display: inline-block;
    width: calc(25% - 10px);
    margin-left: 5px;
    margin-right: 5px;
}

.radioOpt span:hover {
    background-color: unset;
}
.radioOpt span {
    display: block;
    text-align: center;
    font-size: 1.1vw;
    padding: 0.3vw;
    height: 3vw;
    margin-top: 1vw;
}

.radioOpt span > svg > path {
    fill: #D9D9D9;
}
.radioOpt span svg {
    color: var(--primary-color);
    width: 2vw;
    height: 2.6vw;
}

.radioOpt span svg:last-child > path {
    fill: var(--primary-color);
}

.radioOpt span svg:last-of-type {
    left: calc(50% - 1vw);
    width: 2vw;
    height: 2.6vw;
}

.formContainer.stepThree {
    width: 100%;
    margin-top: .5vw;
}

.labelField {
    display: inline-flex;
    width: 30%;
    font-size: 1.2vw;
    vertical-align: top;    
}

.formFieldStepThreeContainer {
    padding-left: 1.5vw;
    padding-right: 0.4vw;
    margin-top: 3vw;
    margin-bottom: 1vw;
}

.valueField {
    display: inline-flex;
    width: calc(70% - 2vw);
    margin-left: 2vw;
}

textarea {
    resize: none;
    width: 100%;
    font-size: 1vw;
    border: 1px solid var(--Rectangle, #D9D9D9);
    border-radius: 0.3vw;
}


.stepFull {
    width: calc(100% - 6vw);
}

.closeIconBtn {    
  position: absolute !important;
  right: 5px;
  top: 5px;
  z-index: 1010;
}


@media (max-width: 900px) {

    .webContainer {
        display: none;
    }

    .mobileContainer {
        display: block;
    }

    .formContainer {
        width: 100%;
        text-align: center;
        margin-bottom: 5vw;
        margin-top: 5vw;
    }

    .fieldContainer {
        width: 90%;
        text-align: left;
        display: inline-block;
    }

    .fieldContainer label {
        font-size: 3vw;
    }

    .fieldContainer input.formField {
        border-radius: 1vw;
        height: 6vw;
        font-size: 3vw;
        width: 97%;
    }

    .errorMsg {
        font-size: 3vw;
        position: relative;
        bottom: 1vw;
    }

    .autocompleteContainer ~ .errorMsg {
        bottom: 0;
    }

    .autocompleteContainer div {
        height: 8vw;
    }

    .autocompleteContainer input {
        font-size: 3vw;
        left: 0.3vw;
    }

    .radioOptMobile span > svg > path {
        fill: #D9D9D9;
    }
    .radioOptMobile span svg {
        color: var(--primary-color);
    }
    .radioOptMobile span svg:last-child > path {
        fill: var(--primary-color);
    }

    .labelField {
        display: block;
        width: 100%;
        font-size: 3.5vw;
    }

    .valueField {
        display: block;
        width: 100%;
        margin-left: 0;
    }    

    textarea {
        width: 100%;
        font-size: 3vw;
        border: 1px solid var(--Rectangle, #D9D9D9);
        border-radius: 1vw;
        height: 12vw;
        margin-top: 2vw;
    }

}