
tr.customRow td span {
  position: relative;
  top: 7.25px;
  padding-bottom: 8px !important;
}

.textWithCopy{
  display: flex;
  bottom: 7.5px;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
}

tr.customRow td span.restartIconCont {
  position: relative;
  bottom: 10px;
  top: unset;
}

.restartIcon {
  position: relative;
  bottom: 1px;
  cursor: pointer;
}

.restartIcon svg {
  width: 20px;
  left: 8px;
  position: relative;

}

.container-abm-page{
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  height: calc(90vh - 18px);
}

.table-templates-container {
  padding-top: 15px;
  height: calc(100% - 70px);
  overflow: hidden;
  display: inline-flex;   
  min-width: calc(100% - 30px);
  flex-direction: column;
}

.refreshIconModal > span{
  top: 4px;
}

@media (max-width: 1366px) {
  .errorTransactionPage{
      height: calc(125vh - 20px);
  }

  .table-templates-container{
      height: calc(125vh - 110px);
  }

  .table-templates-container-form-visible{
      height: calc(125vh - 20px);
  }
}