.table-templates-container-form-visible {
    width: calc(85% - 30px);
    min-width: unset;
    padding-top: 15px;
    height: calc(100% - 70px);
    overflow: hidden;
    display: inline-flex;
    flex-direction: column;
}

.table-templates-container-form-visible div table {
    min-width: 650px;
}

.container-abm-page{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    height: 90vh;
}

.table-templates-container {
    padding-top: 15px;
    height: calc(100% - 70px);
    overflow: hidden;
    display: inline-flex;   
    min-width: calc(100% - 30px);
    flex-direction: column;
}

.abm-container {
    display: inline-flex;
    position: relative;
}

.hidden-abm {
    background-color: #C4CED9;

}

.add-form-container {
    display: table;
    width: 28%;
    min-width: fit-content;
    background-color: #C4CED9;
    border-radius: 10px;
    margin-left: 16px;
    padding: 15px;
    position: relative;
    top: 10px;
}



.form-close-button {
    position: absolute;
    right: 15px;
    top: 20px;
    z-index: 1;
    color: white;
    cursor: pointer;
}

.edit-form-container {
    background-color: rgba(114, 28, 49, .2);
    display: table;
    width: 28%;
    min-width: fit-content;
    border-radius: 10px;
    margin-left: 16px;
    padding: 15px;
    position: relative;
    top: 10px;
}

.btn-show-form {
    position: fixed;
    background-color: #C4CED9;
    padding: 10px;
    border-radius: 10px 0px 0px 10px;
    right: 0px;
    margin-top: 15px;
    cursor: pointer;
}

.title-form {
    color: white;
    width: 100%;
    display: inline-flex;
    font-size: 25px;
    font-family: 'Gilroy-bold';
    position: relative;
    top: 10px;
    border-bottom: 1px solid;
    padding-bottom: 15px;
    margin-bottom: 30px;
}

.title-form svg {
    height: 35px;
    width: 35px;
}

.buttons-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

span.title-form-text {
    padding-left: 15px;
    position: relative;
    top: 3px;
}

.setting-attr-title {
    font-family: 'Gilroy-Bold';
    font-size: 13px;
    color: #34415A;
    display: block;
}

.setting-attr-input {
    background-color: #f5f6fa;
    border-radius: 10px;
    border-width: 0;
    font-family: Gilroy-Medium;
    font-size: 13px;
    height: 31.5px;
    margin-top: 9px;
    margin-bottom: 15px;
    padding-left: 10px;
    width: calc(100% - 25px);
}


.setting-attr-input::placeholder {
    font-family: Gilroy-Medium;
    color: #C4CED9;
    font-size: 13px;
}


.split-fifty {
    width: 50%;
    display: inline-block;
}



.no-selection {
    color: #C4CED9;
    font: unset;
    font-size: 13px;
    font-family: 'Gilroy-Medium';
}

div.select-filter {
    min-width: calc(100% - 15px);
    max-width: calc(100% - 15px);
    margin-top: 9px;
    margin-bottom: 15px;
}

div.select-filter fieldset.MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}

div.select-filter > div {
    background: #F5F6FA;
    border-radius: 10px;
    height: 34px;
    font-size: 13px;
    font-family: 'Gilroy-Medium';
}

.select-option {
    font-size: 13px;
    font-family: 'Gilroy-Medium';
    background-color: transparent !important;
}

textarea.setting-attr-input {
    resize: none;
    padding: 10px 10px;
    width: calc(100% - 35px);
    height: 140px;
}


button.btn-newsan-cancel {
    height: 41px;
    padding: 0px 15px 0px 15px;
    margin-right: 10px;
    border-radius: 10px;
    color: var(--secondary-color);
    background-color: transparent;
    font-family: 'Gilroy-Medium';
    text-transform: none;
    font-size: 15px;
    border: 1px solid var(--secondary-color);
    box-shadow: none;
}
button.btn-newsan-cancel:hover {
    background-color: inherit;
}

button.button-newsan-red, button.button-newsan-grey {
    width: 105px;
    margin-left: 10px;
}

button.button-newsan-red{
    height: 41px;
    padding: 0px 15px 0px 15px;
    margin-right: 10px;
    border-radius: 10px;
    color: white;
    background-color: var(--primary-color);
    font-family: 'Gilroy-Medium';
    text-transform: none;
    font-size: 15px;
    border: 1px solid var(--primary-color);
    box-shadow: none;
}

button.button-newsan-red:hover {
    background-color: var(--primary-color);
}

button.button-newsan-grey{
    height: 41px;
    padding: 0px 15px 0px 15px;
    margin-right: 10px;
    border-radius: 10px;
    color: white;
    background-color: var(--secondary-color);
    font-family: 'Gilroy-Medium';
    text-transform: none;
    font-size: 15px;
    border: 1px solid var(--secondary-color);
    box-shadow: none;
}

button.button-newsan-grey:hover {
    background-color: var(--secondary-color);
}

.container-abm-page{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    height: calc(90vh - 18px);
}

.editIcon {
    width: 20px;
    height: 20px;
    position: relative;
    bottom: 5px;
    color: var(--primary-color);
}

div.storesDataTable table tbody tr td:last-of-type span{
    text-align: right;
    margin-left: auto;
}

span.multipleCheckbox{
    color: var(--primary-color);
    padding: 4px;
}

span.multipleCheckbox svg{
    fill: var(--primary-color)
}

@media (max-width: 1366px) {
    .storesPage{
        height: calc(125vh - 20px);
    }

    .table-templates-container{
        height: calc(125vh - 110px);
    }

    .table-templates-container-form-visible{
        height: calc(125vh - 20px);
    }
}