div.menu-autoCompleteMultiple div.MuiMenu-paper {
    border-radius: 10px;
    background-color: #FFFFFF;
    min-width: 330px;
    width: 23vw;
    min-height: 360px;
}

div.menu-autoCompleteMultiple div.MuiAutocomplete-paper {
    border-radius: 10px;
    background-color: #FFFFFF;
    font-size: 12px;
}

div.menu-autoCompleteMultiple div.MuiMenu-paper > ul {
    padding: 8px;
    text-align: center;
    max-width: calc(100% - 50px);
}

.input-options-selected{
    background: #F5F6FA;
    border-radius: 10px;
    height: 24px;
    width: 90%;
    font-size: 13px;
    font-family: 'Gilroy-Medium';
    padding-left: 4px;
    padding-top: 7px;
    margin-top: 9px;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid rgba(128, 128, 128, 0.282);
}


.input-options-selected:hover{
    border: 1px solid black;
}