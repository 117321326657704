.link{
    color: blue;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .popover {
    pointer-events: none;
  }
  
  .paper {
    padding: 12px;
    color: white;
    background-color: var(--primary-color);
  }