.table-container-load {
    padding-top: 40px;
    height: 260px;
    overflow: hidden;
}

.assign-stock-grids-container {
    padding: 8px 20px;
    background-color: white;
    border-radius: 10px;
    width: 87%;
    position: relative;
}

.gridContainer {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-align: center;
    min-height: 200px;
    height: calc(100vh - 325px);
}

.side-grid {
    display: inline-flex;
    width: 49%;
    overflow: hidden;
    height: 280px;
}

.side-grid.new-stock-side {
    padding-left: 20px;
    text-align: right;
    width: calc(50% - 30px);
}

.static-card-assign-stock {
    min-width: 200px;
    min-height: 65px;
    display: inline-block;
    border-radius: 10px;
    text-transform: uppercase;
    font-family: 'Gilroy';
    font-weight: 700;
    color: #34415A;
    padding: 14px;
    vertical-align: top;
    text-align: left;
    background-color:  rgba(203, 191, 217, 1);
    margin-right: 20px;
    width: calc(22% - 20px);
}

.desc-card-assign-stock {
    background-color:  rgba(203, 191, 217, .6);
    width: calc(34% - 20px);
}

.info-card-assign-stock {
    background-color:  rgba(203, 191, 217, .2);
    width: calc(29% - 20px);
    position: relative;
    overflow: auto;
    max-height: 115px;
}


.static-card-assign-stock .card-title {
    font-size: 18px;
    display: block;
    margin-bottom: 5px;
}

.static-card-assign-stock .card-desc {
    font-family: 'Gilroy-Bold';
    font-size: 25px;
    display: block;
}

.assign-table-title {
    display: inline-block;
    width: 50%;
    text-align: center;
    text-transform: uppercase;
    color: var(--primary-color);
}


.assign-stock-table table {
    border-spacing: 1px !important;
    background-color: white !important;
    min-width: -moz-fit-content !important;
    min-width: fit-content !important;
}

.assign-stock-table thead {
    height: 40px !important;
    width: 100% !important;
    margin-bottom: 0px !important;
    box-shadow: -5px 12px 8px 0px rgba(0, 0, 0, 0.02) !important;
    background-color: #f5f6fa !important;
}

.assign-stock-table tbody {
    background-color: white !important;
    position: relative !important;
    top: 15px !important;
    width: calc(100% - 14px) !important;
}

.assign-table-table thead tr th span.data-header span.header-title{
    margin-left: 0px;
}


.MuiPaper-rounded.newsan-table.assign-stock-table thead th {
    font-family: 'Gilroy-Bold';
    color: var(--secondary-color);
}


.assign-stock-header-container {
        margin-bottom: 30px;
}

.assign-stock-header-title {
    display: block;
    width: 100%;
    font-family: 'Gilroy-Bold';
    font-size: 28px;
    color: var(--secondary-color);
    margin-bottom: 20px;
    margin-top: -18px;
}

.assing-stock-character-container {
    display: block;
    position: absolute;
    bottom: -25px;
    right: -150px;
}

.assing-stock-character-container svg {
    height: 320px;
    width: 160px;
}


.static-card-assign-stock.info-card-assign-stock .card-desc {
    font-family: 'Gilroy-Bold';
    font-size: 14px;
    display: block;
}

.MuiPaper-rounded.newsan-table.assign-stock-table .custom-element {
    width: 75%;
    display: inline-block;
}

.MuiPaper-rounded.newsan-table.assign-stock-table .custom-element input {
    background-color: transparent;
    border: 1px solid rgba(196, 206, 217, 1);
    color: rgba(196, 206, 217, 1);
    text-align: center;
    padding-left: 0px;
    font-size: 0.875rem;;
}

.MuiPaper-rounded.newsan-table.assign-stock-table tbody tr td:last-of-type > span {
    width: 100%;
    margin-right: 0;
}

.MuiPaper-rounded.newsan-table tbody tr:first-of-type td {
    padding-top: 0px;
}

.card-refres-icon {
    padding: 3px;
    position: absolute;
    top: 10px;
    background-color: var(--primary-color);
    color: white;
    right: 10px;
    border-radius: 10px;
    width: 25px;
    height: 25px;
    text-align: center;
    padding-top: 6px;
    cursor: pointer;
}

.buttonAddReserve {
    position: absolute;
    left: 0px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.buttonAddReserve svg {
    width: 25px;    
    height: 25px;
    cursor: pointer;
    padding-top: 10px;
}

.buttonAddReserve span {
    display: inline-block;
}

.buttonAddReserve span.text {
    font-size: 14px;
    cursor: pointer;
}

.okBtn svg path {
    fill: var(--primary-color);
}

.cancelBtn svg path {
    fill: var(--secondary-color);
}

.checkButton {
    position: relative;
    height: 15px;
    width: 15px;
    display: inline-block;
    padding: 3px;
    background: var(--primary-color);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 5px;
}

.checkButton svg {
    height: 15px;
    width: 15px;
}

.customElement input {
    background-color: #f5f6fa;
    border-radius: 10px;
    border-width: 0;
    font-family: Gilroy-Medium;
    font-size: 13px;
    height: 31.5px;
    padding-left: 10px;
    width: calc(100% - 50px);
}

.redRow {
    background-color: rgba(255, 194, 194, 0.888);
}

.closeIconBtn {    
  position: absolute;
  right: -5px;
  top: -5px;
}

.editIcon svg {
    width: 20px;
    height: 20px;
    position: relative;
    bottom: 5px;
    color: var(--primary-color);
}

.reserveCellData {
    position: relative;
    bottom: 5px;
    margin-right: 8px;
    padding-left: 30px;
}

.warningIcon {
    color: orange;
    cursor: help;
}

tbody tr td > span.customAction{
    top: 12px !important
}

@media (max-width: 1366px) {
    .assignStockPage{
        height: calc(125vh - 20px);
    }

    .gridContainer{
      height: calc(90vh - 108px);
    }
}
