/* .table-templates-container.form-visible {
  width: calc(65% - 30px);
  min-width: unset;
}

.table-templates-container.form-visible .MuiPaper-rounded.newsan-table table {
  table-layout: fixed;
}

.table-templates-container.form-visible
  .MuiPaper-rounded.newsan-table
  table
  thead
  tr
  th:last-child {
  width: 25px;
}

.table-templates-container {
  padding-top: 15px;
  height: calc(100% - 70px);
  overflow: hidden;
  display: inline-flex;
  min-width: calc(100% - 30px);
  flex-direction: column;
}

.abm-container {
  display: inline-flex;
  position: relative;
  width: 28%;
}

.hidden-abm {
  background-color: #c4ced9;
}

.add-form-container {
  display: table;
  width: 28%;
  min-width: fit-content;
  background-color: #c4ced9;
  border-radius: 10px;
  margin-left: 16px;
  padding: 15px;
  position: relative;
  top: 10px;
}

.form-close-button {
  position: absolute;
  right: 15px;
  top: 20px;
  z-index: 1;
  color: white;
  cursor: pointer;
}

.edit-form-container {
  background-color: rgba(114, 28, 49, 0.2);
  display: table;
  width: 28%;
  min-width: fit-content;
  border-radius: 10px;
  margin-left: 16px;
  padding: 15px;
  position: relative;
  top: 10px;
}

.btn-show-form {
  position: fixed;
  background-color: #c4ced9;
  padding: 10px;
  border-radius: 10px 0px 0px 10px;
  right: 0px;
  margin-top: 15px;
  cursor: pointer;
}

.title-form {
  color: white;
  width: 100%;
  display: inline-flex;
  font-size: 25px;
  font-family: 'Gilroy-bold';
  position: relative;
  top: 10px;
  border-bottom: 1px solid;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

.title-form svg {
  height: 35px;
  width: 35px;
}

.buttons-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

span.title-form-text {
  padding-left: 15px;
  position: relative;
  top: 3px;
}

.setting-attr-title {
  font-family: 'Gilroy-Bold';
  font-size: 13px;
  color: #34415a;
  display: block;
  margin-bottom: 5px;
}

.setting-attr-input {
  background-color: #f5f6fa;
  border-radius: 10px;
  border-width: 0;
  font-family: Gilroy-Medium;
  font-size: 13px;
  height: 31.5px;
  margin-top: 9px;
  margin-bottom: 9px;
  padding-left: 10px;
  width: calc(100% - 25px);
}

.setting-attr-input::placeholder {
  font-family: Gilroy-Medium;
  color: #c4ced9;
  font-size: 13px;
}

.split-fifty {
  width: 50%;
  display: inline-block;
}

.no-selection {
  color: #c4ced9;
  font: unset;
  font-size: 13px;
  font-family: 'Gilroy-Medium';
}

div.select-filter {
  min-width: calc(100% - 15px);
  max-width: calc(100% - 15px);
  margin-top: 9px;
  margin-bottom: 9px;
}

div.select-filter fieldset.MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

div.select-filter > div {
  background: #f5f6fa;
  border-radius: 10px;
  height: 34px;
  font-size: 13px;
  font-family: 'Gilroy-Medium';
}

.select-option {
  font-size: 13px;
  font-family: 'Gilroy-Medium';
}

textarea.setting-attr-input {
  resize: none;
  padding: 10px 10px;
  width: calc(100% - 35px);
  height: 140px;
}

.MuiButton-contained.btn-newsan-cancel {
  height: 41px;
  padding: 0px 15px 0px 15px;
  margin-right: 10px;
  border-radius: 10px;
  color: #34415a;
  background-color: transparent;
  font-family: 'Gilroy-Medium';
  text-transform: none;
  font-size: 15px;
  border: 1px solid #34415a;
  box-shadow: none;
}
.MuiButton-contained.btn-newsan-cancel:hover {
  background-color: inherit;
}

.MuiButton-contained.button-newsan-red.btn-warehouse,
.MuiButton-contained.button-newsan-grey.btn-warehouse {
  width: 105px;
  margin-left: 10px;
}

.container-abm-page {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
} */

.modalContainer {
  margin: 0;
  width: 90%;
  max-width: 1200px;
  /* height: 50vh; */
  padding: 30px;
  background: white;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-around;
  align-items: center;
}

.modalTitle {
  display: inline-block;
  width: 100%;
  font-family: Gilroy-Bold;
  color: var(--secondary-color);
  font-size: 18px;
}

.errorText {
  color: red;
  text-transform: uppercase;
}

.editIcon {
  width: 20px;
  height: 20px;
  position: relative;
  bottom: 5px;
  color: var(--primary-color);
}

.modalButtonContainer{
  text-align: center;
  display: flex;
  align-items: center;
}

button.totalContainer-green{
  position: absolute;
  right: 9%;
  font-family: Gilroy-Bold;
  font-size: 18px;
  background-color: green;
  width: calc(16% - 50px);
}

button.totalContainer-green:hover{
  background-color: green;
  cursor: unset;
}

button.totalContainer-red{
  position: absolute;
  right: 9%;
  font-family: Gilroy-Bold;
  background-color: transparent;
  color: red;
  width: calc(16% - 50px);
  border: 1px red solid;
  font-size: 18px;
}

button.totalContainer-red:hover{
  background-color: transparent;
  cursor: unset;
}

.container-abm-page{
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  height: calc(90vh - 18px);
}

.table-templates-container {
  padding-top: 15px;
  height: calc(100% - 70px);
  overflow: hidden;
  display: inline-flex;   
  min-width: calc(100% - 30px);
  flex-direction: column;
}

@media (max-width: 1366px) {
  .stockDistributionPage{
      height: calc(125vh - 20px);
  }

  .table-templates-container{
      height: calc(125vh - 110px);
  }

  .table-templates-container-form-visible{
      height: calc(125vh - 20px);
  }
}