div.setting-checkbox-list {
    min-width: calc(100% - 15px);
    max-width: calc(100% - 15px);
    display: flex;
}

div.setting-checkbox-list fieldset.MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}

div.setting-checkbox-list > div {
    background: #F5F6FA;
    border-radius: 10px;
    height: 136px;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    overflow-y: auto;
    padding-left: 10px;
    margin-left: 10px;
}

.setting-attr-title {
    font-family: 'Gilroy-Bold';
    font-size: 13px;
    color: #34415A;
    display: block;
    margin-bottom: 5px;
}

.setting-attr-title {
    font-family: 'Gilroy-Bold';
    font-size: 13px;
    color: #34415A;
    display: block;
    margin-bottom: 5px;
}


.unchecked > rect {
    fill:transparent;
    cursor: pointer;
}

.checked > rect {
    fill: var(--primary-color);
    cursor: pointer;
}

.check-list-container {
    width: 100%;
    height: 19px;
    padding: 5px 0px;
    white-space: nowrap;
}

.check-container {
    height: 20px;
    display: inline-block;
    position: relative;
    top: 5px;
}

.clear-container{
    height: 20px;
    display: inline-block;
}

.clear-container svg{
    fill: var(--primary-color);
}

.check-label {
    height: 20px;
    display: inline-block;
    max-width: calc(100% - 20px);
    overflow: hidden;
    margin-left: 10px;
}

.clear-label{
    font-weight: bold;
    color: var(--primary-color);
    height: 20px;
    display: inline-block;
    max-width: calc(100% - 20px);
    overflow: hidden;
    margin-left: 10px;
}

.check-container-padding {
    padding-inline: 15px;
    padding-bottom: 10px;
    width: 100%;
}

