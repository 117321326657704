
tr.customRow td span {
    position: relative;
    top: 7.25px;
    color: black;
}
tr.customRow td:last-of-type span{
    position: relative;
    top: 7.25px;
    padding-bottom: 12px !important;
}

.textWithCopy{
    display: flex;
    bottom: 10px;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
}

tr.customRow td span.restartIconCont {
    position: relative;
    bottom: 18px;
    top: unset;
}

tr.customRow td span.statusWithoutIcon {
    position: relative;
    bottom: 8px;
    top: unset;
}

.restartIcon {
    position: relative;
    bottom: 1px;
    cursor: pointer;
}

.restartIcon svg {
    width: 20px;
    left: 8px;
    position: relative;

}

.container-abm-page{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    height: calc(90vh - 18px);
}

.table-templates-container {
    padding-top: 15px;
    height: calc(100% - 70px);
    overflow: hidden;
    display: inline-flex;   
    min-width: calc(100% - 30px);
    flex-direction: column;
}

.refreshIconModal > span{
    top: 4px;
}

.helpModal{
    position: absolute;
    top: 50%;
    left: 50%;
    height: fit-content;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 24px;
    border-radius: 10px;
    padding: 24px;
    border: 3px solid var(--secondary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.helpModal p{
    line-height: 120%;
    text-align: justify;
}

.helpModal p div.sangria{
    text-indent: 4em;
}

.helpModal strong{
    color: var(--primary-color);
    line-height: 120%;
}

.helpModal button{
    background-color: var(--secondary-color);
    color: white;
    margin-top: 24px;
}
.helpModal button:hover{
    background-color: var(--secondary-color) ;
    opacity: 80%;
    color: white;
}

@media (max-width: 1366px) {
    .movementPage{
        height: calc(125vh - 20px);
    }
  
    .table-templates-container{
        height: calc(125vh - 110px);
    }
  
    .table-templates-container-form-visible{
        height: calc(125vh - 20px);
    }
}