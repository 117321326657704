.input-range-container {
    background: #F5F6FA;
    border-radius: 10px;
    height: 24px;
    width: 90%;
    font-size: 13px;
    font-family: 'Gilroy-Medium';
    padding-left: 4px;
    padding-top: 7px;
    margin-top: 9px;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid rgba(128, 128, 128, 0.282);
}

.input-range-container:hover{
    border: 1px solid black;
}


div.menu-range-date div.MuiPaper-rounded {
    border-radius: 10px;
    background-color: #FFFFFF;
    min-width: 330px;
    width: 23vw;
    min-height: 260px;
}

div.menu-range-date div.MuiPaper-rounded > ul {
    padding: 25px;
    text-align: center;
    max-width: calc(100% - 50px);
}

.input-date-container {
    display: inline-block;
    width: 35%;

}

.title-input-range {
    margin-bottom: 10px;
    display: block;
    font-family: 'Gilroy-Bold';
    font-size: 13px;
    text-align: left;
}


.input-date-container input[type=date] {
    background: #F5F6FA;
    border-radius: 10px;
    height: 34px;
    width: 100%;
    font-size: 13px;
    font-family: 'Gilroy-Medium';
    border: none;
    padding-left: 5px;
    padding-right: 5px;
}

.input-date-container input[type=date] ~ i {
    display: inline-block;
    /* background-image: url('./assets/icons/currency-icon.svg'); */
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 6px;
    left: 5px;
}

input[type=date]::-webkit-calendar-picker-indicator { 
    cursor: pointer; /* Para el ícono del calendario */
}

button.button-calendar {
    width: calc(100% - 25px);
    text-align: center;
    color: #34415A;
    font-size: 13px;
    border: 1px solid #34415A;
    border-radius: 12px;
    margin-top: 15px;
    text-transform: unset;
    font-family: 'Gilroy-Medium';
    padding: 4px 2px;
}

button.button-clear{
    width: calc(100% - 25px);
    text-align: center;
    color: white;
    font-size: 13px;
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    border-radius: 12px;
    margin-top: 15px;
    text-transform: unset;
    font-family: 'Gilroy-Medium';
    padding: 4px 2px;
}

button.button-clear:hover {
    background-color: var(--primary-color, 80%);
}
