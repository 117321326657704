* {
  --primary-color: #721c31;
  --secondary-color: #34415A;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loadingPage{
  position: fixed;
  height: 100%;
  width: 100%;
  text-align: center;
  z-index: 1201;
  left: 0px;
  background: rgba(0, 0, 0, 0.15);
}

.circleProgress{
  margin-top: calc(-20px + 50vh);
}

@media (max-width: 1366px) {
  .zoom-container {
    transform: scale(0.8);
    transform-origin: top left;
    width: 125%; /* Ajusta para compensar el escalado */
    height: 100vh;
  }

  div.newsan-sidebar .MuiDrawer-paper{
    height: calc(125vh - 40px);
  }

  .loadingPage{
    position: fixed;
    height: 125%;
    width: 100%;
    text-align: center;
    z-index: 1201;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.15);
  }
  
  .circleProgress{
    margin-top: calc(-20px + 62.5vh);
  }
}

code {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}


@font-face {
    font-family: 'Gilroy-Bold';
    font-weight: bold;
    src: url(/src/assets/fonts/gilroy/Gilroy-Bold.ttf);
}


@font-face {
    font-family: 'Gilroy-Medium';
    font-weight: bold;
    src: url(/src/assets/fonts/gilroy/Gilroy-Medium.ttf);
}

@font-face {
    font-family: 'Gilroy';
    src: url(/src/assets/fonts/gilroy/Gilroy-Regular.ttf);
}

@font-face {
    font-family: 'Gilroy';
    font-weight: 700;
    src: url(/src/assets/fonts/gilroy/Gilroy-SemiBold.ttf);
}

@font-face {
    font-family: 'Inter';
    src: url(/src/assets/fonts/inter/Inter-VariableFont_slnt\,wght.ttf);
}

@font-face {
  font-family: 'Assistant-SemiBold';
  src: url(/src/assets/fonts/assistant/Assistant-SemiBold.ttf);
}

.MuiListItemButton-gutters.newsan-sidebar-item:hover, .MuiListItemButton-gutters.newsan-sidebar-item:hover .MuiListItemIcon-root {
  color: var(--primary-color);
  background-color: unset;
  p {
    font-weight: 600;
  }
}
.MuiListItemButton-gutters.newsan-sidebar-item {
  padding: 15px;
}

.topbar-icon-section {
  display: inline-block;
  height: 64px;
  width: 20px;
  vertical-align: top;
  color: var(--primary-color);
  position: relative;
}

.topbar-icon-section .MuiSvgIcon-fontSizeLarge{
  position: absolute;
  right: 0px;
}

.topbar-title-section {
  display: inline-block;
  height: 64px;
  width: calc(100% - 29px);
  padding-top: 12px;
  padding-left: 9px;
}

.topbar-title {
  display: inline-block;
  width: 100%;
  font-family: Gilroy-Bold;
  color: var(--secondary-color);
  font-size: 18px;
  white-space: nowrap;
}

.topbar-subtitle {
  display: inline-block;
  font-family: Gilroy-Bold;
  color: var(--secondary-color);
  font-size: 10px;
}

.newsan-logo {
  background-image: url('../src/assets/images/newsan-black-logo.png');
  width: 165px;
  height: 52px;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.newsan-icon {
  background-image: url('../src/assets/images/newsan-icon.png');
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
}
.newsan-sidebar {
    width: 220px;
    margin-top: 15px;
    border-radius: 8px;
    flex-shrink: 0;
}

.newsan-sidebar .MuiDrawer-paper {
    width: 220px;
    box-sizing: 'border-box';
    border-right: 0px;
    background-color: #FFFFFF;
    color: var(--secondary-color);
    margin-top: 15px;
    border-radius: 10px;
    box-shadow: -5px 12px 8px 0px rgba(0, 0, 0, 0.02);
    margin-left: 20px;
    height: calc(100% - 40px);
    z-index: 999; /* abajo de 1000 para que se vea el Snackbar del boton de copiado */
}

.newsan-sidebar.small {
  width: 80px;
}

.newsan-sidebar.small .MuiDrawer-paper {
  width: 80px;
}

.notification-container {
  height: 40px;
  width: 40px;
  border-radius: 10px !important;
  background-color: #FFFFFF !important;
}

.search-container {
  width: calc(100% - 130px);
  height: 40px;
  background-color: rgb(255, 255, 255);
  display: inline-block;
  margin-right: 20px;
  top: 3px;
  position: relative;
  border-radius: 6px;
  text-align: left;
}

.search-container input::placeholder {
  font-family: Gilroy-Medium;
  font-size: 15px;
  color: var(--primary-color);
  opacity: 1; /* Firefox */
}

.upload-icon {
  background-image: url('../src/assets/images/upload-icon.png');
  width: 20px; 
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 5px;
}

.MuiButton-contained.button-newsan-red {
  height: 41px;
  padding: 0px 15px 0px 15px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: var(--primary-color);
  font-family: 'Gilroy-Medium';
  text-transform: none;
  font-size: 15px;
}

.MuiButton-contained.button-newsan-red:hover {
  background-color: var(--primary-color);
  opacity: .9;
}

.MuiButton-contained.button-newsan-grey {
  height: 41px;
  padding: 0px 15px 0px 15px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: var(--secondary-color);
  font-family: 'Gilroy-Medium';
  text-transform: none;
  font-size: 15px;
}

.MuiButton-contained.button-newsan-grey:hover {
  background-color: var(--secondary-color);
  opacity: .9;
}

.menu-active path {
  fill: var(--primary-color);
  color: var(--primary-color);
}

.menu-inactive path {
  fill:var(--secondary-color);
  color:var(--secondary-color);
}

div.menu-notification {
  top: 5px;
}

div.menu-notification div.MuiPaper-rounded {
  border-radius: 10px;
  background-color: #FFFFFF;

}

.menu-notification ul {
  min-width: 315px;
  width: 21.5vw;
}

.menu-notification ul li {
  min-height: 96px;
  padding-inline: 30px;
}

.menu-notification ul li div.col-notif {
    min-height: 96px;
    position: relative;
}

.menu-notification ul li div.col-notif.col-icon {
  width: 10%;
  height: 100%;
  position: absolute;
}


.menu-notification ul li div.col-notif.col-icon .menu-notif-icon-container {
  width: 22px;
  height: 22px;
  display: block;
  position: relative;
  padding: 5px;
  background-color: #F6F0F2;
  border-radius: 5px;
  right: 10px;
  top: 8px;
  text-align: center;
}

.menu-notification ul li div.col-notif.col-icon svg {
  width: 15px;
  height: 15px;
}

.menu-notification ul li div.col-notif.col-icon svg path {
  fill: var(--primary-color);
}

.menu-notification ul li div.col-notif.col-text {
  width: 70%;
  color: var(--secondary-color);
  margin-left: 13%;
  font-size: 13px;
}

.menu-notification ul li div.col-notif.col-text .notif-title {
  display: block;
  font-family: 'Gilroy-Bold';
}

.menu-notification ul li div.col-notif.col-text .notif-detail {
  display: block;
  font-family: 'Gilroy-Medium';
  white-space: break-spaces;
}


.menu-notification ul li div.col-notif.col-date {
  width: 20%;
  position: absolute;
  right: 0;
  bottom: 5px;
}

.menu-notification ul li div.col-notif.col-date .notif-date-info {
  position: absolute;
  bottom: 0;
  right: 10px;
  font-size: 10px;
  font-family: 'Gilroy-Medium';
}


.menu-notification ul li div.col-notif.col-date .notif-date-info svg {
  padding-left: 5px;
  top: 8px;
  position: relative;
  width: 20px;
  height: 20px;
}

div.menu-notification {
  top: 5px;
}

div.menu-user div.MuiPaper-rounded {
  border-radius: 10px;
  background-color: #FFFFFF;
  min-width: 395px;
  width: 25vw;

}

div.menu-user ul {
  padding-inline: 20px;
}

div.menu-user ul .user-info {
  min-height: 105px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.profile-pic-container {
  display: inline-block;
  width: 30%;
  min-width: 110px;
}

.profile-detail-container {
  display: inline-block;
  width: 68%;
  height: 105px;
  position: absolute;
  top: 25px;
}

.profile-detail-container span {
  display: block;
  margin-bottom: 5px;
}

.profile-detail-name {
  font-family: 'Gilroy';
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: var(--primary-color);
}

.profile-detail-email {
  font-family: Gilroy-Medium;
  font-size: 15px;
  line-height: 18px;
  color: var(--secondary-color);
}

.profile-detail-rol {
  font-family: Gilroy-Medium;
  font-size: 15px;
  line-height: 18px;
  color: var(--secondary-color);
}

div.menu-user ul .user-info .profile-pic {
  min-height: 100px;
  min-width: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 40px;
}

button.search-button svg {
  width: 19px;
  height: 20px;
  position: relative;
  left: 5px;
  bottom: 1px;
}


.menu-user .menu-user-buttons-container button {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  color: #F5F6FA;
  padding-bottom: 3px;
}

.menu-user .menu-user-buttons-container button svg {
  position: relative;
  top: 8px;
  margin-right: 5px;
}

button span.button-label {
  padding-left: 10px;
}

/* Custom scrollbar styles */
.nwsn-scroll,
.nwsn-scroll .MuiDrawer-paper {
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #c1c0c0;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--primary-color);
  }
}


.modal-overlay {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-box {
  display: block;
  background: white;
  width: 50%;
  height: 70%;
  padding: 1rem;
  border-radius: 1rem;
  min-width: 400px;
  min-height: 430px;
}

div.select-opt {
  min-width: calc(100% - 15px);
  max-width: calc(100% - 15px);
}

div.select-opt fieldset.MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

div.select-opt > div {
  cursor: pointer;
  background: #F5F6FA;
  border-radius: 10px;
  height: 34px;
  font-size: 13px;
  font-family: 'Gilroy-Medium';
}

.select-option {
  font-size: 13px;
  font-family: 'Gilroy-Medium';
}
/* 
.dialog-md .MuiDialog-container{
  width: 700px;
}
 */
