.iconRed path{
    fill: #d90337;
}

.iconYellow path{
    fill: rgb(255, 149, 0);
}

.iconBlue path{
    fill: rgb(0, 178, 27);
}

.editIcon {
    width: 20px;
    height: 20px;
    position: relative;
    bottom: 5px;
    color: var(--primary-color);
}
.tableActionIcon {
    color: var(--primary-color);
}
.readRow{
    background-color: #f5f5f5 !important;
}

.unreadRow td span{
    font-weight: bold;
}
.unreadRow td span p{
    font-weight: bold;
}

button.eliminateButton{
    margin-bottom: 12px;
    height: 41px;
    padding: 0px 15px 0px 15px;
    margin-right: 10px;
    border-radius: 10px;
    font-family: 'Gilroy-Medium';
    background-color: var(--secondary-color);
    text-transform: none;
    font-size: 15px;
}

button.eliminateButton:hover {
    background-color: var(--secondary-color);
    opacity: .9;
  }

button.eliminateButton span{
    margin-top: 8px;
}

.eliminateIconHeader {
    position: relative;
    left: 6px;
}

button span.readIconHeader{
    position: relative;
    bottom: 3px !important
}

button span.readIconHeader svg{
    fill: var(--primary-color)
}

button span.unreadIconHeader{
    position: relative;
    bottom: 3px !important
}

.unreadIconHeader svg{
    fill: var(--primary-color)
}

.eliminateIcon{
    position: relative;
    bottom: 2px;
}

span.checkAllContainer{
    display: flex;
    position: relative;
    left: 13px;
    bottom: 3px;
}

.checkAllContainer{
    display: flex;
    align-items: center;
}

.openCheck{
    height: 20px;
    width: 20px;
    border-radius: 10px !important;
}

.customActionHeader{
    max-width: 100%;
    background-color: white;
    border-radius: 10px;
    height: 60px;
}

.customActionHeader thead tr{
    height: 70px;
    display: flex;
    align-items: center;
}

.customActionHeader thead tr th{
    border: none;
}

.createdfield{
    top: 5px;
    position: relative;
}

.messageContainer{
    max-width: 40vw;
    word-break: break-all;
}

button.buttonModal{
    background-color: var(--secondary-color);
    color: white;
    margin-top: 24px;
}
button.buttonModal:hover{
    background-color: var(--secondary-color) ;
    opacity: 80%;
    color: white;
}

.container-abm-page{
    height: calc(90vh - 18px);
}

.tableContainer{
    height: calc(95vh - 18px);
}

div.tableStyle{
    height: 90% !important;
}

@media (max-width: 1366px) {
    .notificationPage{
        height: calc(125vh - 20px);
    }
  
    .table-templates-container{
        height: calc(125vh - 110px);
    }
  
    .table-templates-container-form-visible{
        height: calc(125vh - 20px);
    }
  }