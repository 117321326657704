.MuiPaper-rounded.newsan-table {
    overflow: auto;
    border: none;
    box-shadow: none;
    padding-bottom: 25px;
    border-radius: 10px;
    height: fit-content;
}
.MuiPaper-rounded.newsan-table.scrolleable{
    height: -webkit-fill-available;
    overflow-y: auto;
}

.MuiPaper-rounded.newsan-table table {
    border-spacing: 1px !important;
    background-color: #f5f5f5;
}



.MuiPaper-rounded.newsan-table thead {
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
    /* display: inline-block; */
    box-shadow: -5px 12px 8px 0px #00000005;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.MuiPaper-rounded.newsan-table thead th{
    padding: 0px;
    border-bottom: 0px;
    height: 40px;
    padding-left: 10px;
    font-family: Gilroy-Medium;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0em;
    color: var(--primary-color);
}

.MuiPaper-rounded.newsan-table thead th span {
        display: inline-block;
        height: 30px;
        overflow: hidden;
}

.MuiPaper-rounded.newsan-table thead th span:first-of-type {
    position: relative;
    top: 5px;
}

.MuiPaper-rounded.newsan-table thead th span i {
        display: block;
        height: 7px;
        cursor: pointer;
        color: #C4CED9;
}

.MuiPaper-rounded.newsan-table thead th span i.sort-active {
        color: var(--primary-color);
}

.MuiPaper-rounded.newsan-table thead th:first-of-type{
    border-radius: 10px 0px 0px 10px;
}

.MuiPaper-rounded.newsan-table thead th:last-of-type{
    border-radius: 0px 10px 10px 0px;
}



.MuiPaper-rounded.newsan-table tbody {
    background-color: white;
    /* display: inline-block; */
    width: calc(100% - 14px);
    position: relative;
    top: 20px;
}

.header-action-cell {
    width: 20px;
    visibility: hidden;
}

.MuiPaper-rounded.newsan-table tbody tr{
    border-radius: 10px 10px 0px 0px;
    /* border-bottom: 1px solid rgba(224, 224, 224, 1); */
    /* border-bottom: none; */
    width: calc(100% - 10px);
    /* display: inline-block; */
}

.MuiPaper-rounded.newsan-table tbody tr .readRow{
    background-color: #f5f5f5;
}

.MuiPaper-rounded.newsan-table tbody tr td {
    height: fit-content;
    padding: 0;
    overflow: hidden;
    vertical-align: top;
}

.MuiPaper-rounded.newsan-table tbody tr:first-of-type td {
    padding-top: 0px;
}

.MuiPaper-rounded.newsan-table tbody tr:first-of-type td:first-of-type {
    border-radius: 10px 0px 0px 0px;
}

.MuiPaper-rounded.newsan-table tbody tr:first-of-type td:last-of-type {
    border-radius: 0px 10px 0px 0px;
}

.MuiPaper-rounded.newsan-table thead th > span.data-header {
    display: inline-block; 
    min-width: 100px;
    width: 100%;
    position: relative;
    top: 2px
}

.MuiPaper-rounded.newsan-table thead th > span.data-header > span.header-title {
    width: calc(100% - 55px);
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    margin-left: 7px;
}

.MuiPaper-rounded.newsan-table tbody tr td > span {
    width: calc(100% - 32px);
    min-height: 33px;
    display: block;
    padding: 12px 16px;
    /* border-bottom: 1px solid rgba(224, 224, 224, 1); */
}

.MuiPaper-rounded.newsan-table tbody tr td:first-of-type > span {
    width: calc(100% - 47px);
}

.MuiPaper-rounded.newsan-table tbody tr td:last-of-type > span {
    width: calc(100% - 47px);
    margin-right: 15px;
    padding-bottom: 8px;
}

.MuiPaper-rounded.newsan-table tbody tr td:last-of-type > span.action-column{
    padding: 5px;
    text-align: center;
}


.MuiPaper-rounded.newsan-table.thinner tbody tr td > span {
    width: calc(100% - 32px);
    min-height: 20px;
    display: inline-block;
    padding: 8px 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.MuiPaper-rounded.newsan-table tbody tr td:first-of-type > span {
    width: calc(100% - 47px);
}

.MuiPaper-rounded.newsan-table.thinner tbody tr td:last-of-type > span {
    width: calc(100% - 47px);
    margin-right: 10px;
}
.MuiPaper-rounded.newsan-table.thinner tbody tr td:last-of-type > span.action-column {
    width: 15px;
}

.MuiPaper-rounded.newsan-table thead th.checkbox-datatable:first-of-type {
    padding-left: 22px;
}

.MuiPaper-rounded.newsan-table thead th.checkbox-datatable {
    width: 45px;
    cursor: pointer;
    min-width: 45px;
}

.MuiPaper-rounded.newsan-table thead th.checkbox-datatable > span{
    bottom: 9px;
    left: 8px;
}

.MuiPaper-rounded.newsan-table thead th.checkbox-datatable > span svg {
    top: 10px;
}

.MuiPaper-rounded.newsan-table thead th.checkbox-datatable > span svg  > rect {
    fill:transparent;
}
.MuiPaper-rounded.newsan-table thead th.checkbox-datatable > span  svg.checked > rect {
    fill: var(--primary-color);
}

.checkbox-datatable {
    width: 45px;
    cursor: pointer;
    min-width: 45px;
}

.checkbox-datatable > span{
    bottom: 9px;
    position: relative;
}

td.checkbox-datatable > span svg {
    top: 10px;
    margin-top: 12px;
}

.checkbox-datatable > span svg  > rect {
    fill:transparent;
}
.checkbox-datatable > span  svg.checked > rect {
    fill: var(--primary-color);
}

.items-showed-legend {
    font-family: Gilroy-Medium;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0em;
    color: #484F59;
}


.arrow-icon {
    font-size: 1.5rem;
    position: absolute;
    right: 7px;
    top: calc(50% - 0.5em);
}

.select-container > div {
    background: white;
    width: 40px;
    height: 20px !important;
    padding: 10px;
    border-radius: 10px !important;
    color: var(--primary-color);
}

.select-container  fieldset {
    border: none;
}

.lbl-rows-per-page {
    padding-right: 15px;
    padding-left: 15px;
    font-family: Gilroy-Medium;
    font-size: 13px;
    color: #484F59;
}

.custom-pagination li > button {
    border-radius: 5px;
    padding-top: 2px;
    height: 20px;
    min-width: 20px;
    font-size: 13px;
    font-family: 'Gilroy-Medium';
    color: #8D8D96
}

.custom-pagination li > button.Mui-selected {
    color: white;
    background-color: var(--primary-color);
}


.actions-menu  div.MuiMenu-paper {
    box-shadow: none;
    border: 1px solid #C4CED9;
    border-radius: 10px;
}

/* .actions-menu  div.MuiMenu-paper ul {
    border-radius: 10px;
} */

.actions-menu  div.MuiMenu-paper ul li {
    border-bottom: 1px solid #C4CED9;
    min-width: 180px;
    padding: 12px 16px;
    color: #34415A;
}

.actions-menu  div.MuiMenu-paper ul li>P {
    font-family: Gilroy-Medium;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
}

.actions-menu  div.MuiMenu-paper ul li:first-of-type {
    padding-top: 4px;
    padding-bottom: 12px;
}

.menu-icon {
    top: 6px;
    position: relative;
    padding-right: 5px;
}

.actions-menu  div.MuiMenu-paper ul li:last-of-type {
    border-bottom: none;
    padding-top: 10px;
    padding-bottom: 5px;
}
.MuiPaper-rounded.newsan-table thead th > span.data-header {
    display: inline-block; 
    min-width: 80px;
    width: 100%;
    position: relative;
    top: 2px
}

.MuiPaper-rounded.newsan-table thead th > span.data-header > span.header-title {
    width: calc(100% - 55px);
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    margin-left: 7px;
}

.MuiPaper-rounded.newsan-table.thinner tbody tr td > span.action-column {
    padding: 0;
}

.custom-element > input {
    background-color: #f5f6fa;
    border-radius: 10px;
    border-width: 0;
    font-family: Gilroy-Medium;
    font-size: 13px;
    height: 31.5px;
    padding-left: 10px;
    width: calc(100% - 25px);
    position: relative;
    /* bottom: 5px; */
}

.custom-element.filter-option-text input {
    bottom: 0px;
}

.custom-element.filter-option-text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}


th.text-left {
    text-align: left;
}
th.text-right {
    text-align: right;
}
th.text-center {
    text-align: center;
}

.MuiPaper-rounded.newsan-table thead th span.data-header i.filter-container {
    display: inline-block;
}

.filter-container svg {
    width: 15px;
    height: 20px;
    position: relative;
    bottom: 7px;
}


span.filter-icon-active svg {
    width: 24px;
    height: 24px;
}

span.filter-icon-active svg path {
    fill: var(--primary-color);
}

.filter-options  div.MuiMenu-paper {
    box-shadow: none;
    border: 1px solid #C4CED9;
    border-radius: 10px;
}

.filter-options  div.MuiMenu-paper ul {
    padding-inline: 10px;
    padding-bottom: 10px;
    max-height: 200px;
    overflow: auto;
}

/* width */
.filter-options div.MuiMenu-paper ul::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.filter-options div.MuiMenu-paper ul::-webkit-scrollbar-track {
  background: #c1c0c0;
}

/* Handle */
.filter-options div.MuiMenu-paper ul::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

.filter-options  div.MuiMenu-paper ul li {
    border-bottom: 1px solid #C4CED9;
    min-width: 180px;
    padding: 12px 16px;
    color: #34415A;
}

.filter-options  div.MuiMenu-paper ul li>P {
    font-family: Gilroy-Medium;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
}

.filter-options  div.MuiMenu-paper ul li:first-of-type {
    padding-top: 4px;
    padding-bottom: 12px;
}

.filter-options  div.MuiMenu-paper ul li:last-of-type {
    border-bottom: none;
    padding-top: 10px;
    padding-bottom: 5px;
}

th span.actIcon{
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.MuiPaper-rounded.newsan-table tbody tr td > span.actIcon {
    display: inline-block !important;
    width: 15px;
    height: 15px;
    cursor: pointer;
    position: relative;
    top: 18px;
    padding: 0px !important;
}

.group-actions-col {
    vertical-align: middle !important;
}

.action-column {
    width: 20px !important;
}
.action-column.grouped {
    width: 19px !important;
    margin-right: 0px !important;
    padding: unset !important;
    padding-right: 10px !important;
}

.actionDisabled {
    opacity: .7;
    cursor: not-allowed;
}

.stock-distribution-table td {
    padding-left: 12px !important;
}
