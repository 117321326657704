.input-options-selected{
    background: #F5F6FA;
    border-radius: 10px;
    height: 24px;
    max-width: 90%;
    font-size: 13px;
    font-family: 'Gilroy-Medium';
    padding-left: 4px;
    padding-top: 7px;
    margin-top: 9px;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid rgba(128, 128, 128, 0.282);
    white-space: nowrap;
    text-overflow: ellipsis;
}


.input-options-selected:hover{
    border: 1px solid black;
}