.table-container-load {
    height: calc(50vh - 70px);
    overflow: hidden;
}

.table-container-load  .table-container {
    height: 100%;
}


.side-upload {
    display: inline-block;
    width: 49%;
    overflow: hidden;
    top: 0px;
    position: absolute;
}

.side-upload.dragDropSide {
    width: 43%;
    height: 100%;
    padding: 10px;
    padding-top: 0px;
}

.side-upload.result-side {
    padding-left: 20px;
    text-align: right;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    width: 57%;
}

.file-status-list-container {
    display: block;
    overflow-y: scroll;
    padding-right: 15px;
    width: 90%;
    height: calc(100% - 62px);
    text-align: center;
    align-content: center;
    background-color: #e9e6e691;
    color: #484F59;
    margin-left: 36px;
    border-radius: 10px;
    margin-bottom: 16px;
    font-family: Gilroy-Bold;
    font-size: 18px;
}


.static-card {
    width: 43%;
    min-height: 27px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 10px;
    text-transform: uppercase;
    font-family: 'Gilroy-Bold';
    color: #34415A;
    padding: 14px 14px 5px 14px;
    vertical-align: top;
    text-align: left;

}
.static-card.success {
    background-color: #C4CED9;
    margin-right: 20px;
}
.static-card.error {
    background-color: #dccbd2;
}

.static-card>.card-title {
    font-size: 18px;
}

.static-card>.card-result {
    font-size: 18px;
}


svg.error-csv-download-icon {
    color: var(--primary-color);
    width: 22px;
    height: 22px;
    margin-left: 5px;
    cursor: pointer;
}

.modal-box.file-modal-error {
    min-width: 350px;
    min-height: 150px;
    width: 350px;
    height: 150px;
}

.modalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    height: fit-content;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 24px;
    border-radius: 10px;
    padding: 24px;
    border: 3px solid var(--secondary-color);
    display: flex;
    flex-direction: column;
    align-items: center
}

.modalContainer button{
    background-color: var(--secondary-color);
    color: white;
    margin-top: 24px;
}
.modalContainer button:hover{
    background-color: var(--secondary-color) ;
    opacity: 80%;
    color: white;
}

button.buttonError{
    background-color: var(--secondary-color);
    color: white;
    text-transform: none;
    text-wrap: nowrap;
    padding: 4px;
}

button.buttonError:hover{
    background-color: var(--secondary-color) ;
    opacity: 80%;
    color: white;
}

.productDescription{
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    width: 150px;
}

.emptyFileContainer{
    width: 90%;
    height: calc(100% - 62px);
    text-align: center;
    align-content: center;
    background-color: #e9e6e691;
    color: #484F59;
    margin-left: 36px;
    border-radius: 10px;
    margin-bottom: 16px;
    font-family: Gilroy-Bold;
    font-size: 18px;
    text-transform: uppercase;
}

.stockLoadPage{
    height: auto;
}
@media (max-width: 1366px) {
    .stockLoadPage{
      height: calc(125vh - 20px);
    }
    
    .table-container-load {
        height: calc(70vh - 75px);
        overflow: hidden;
    }
}