
.formContainer {
    display: inline-block;
    width: 90%;
    text-align: left;
    margin-left: 0.5vw;
    margin-top: 2vw;
}

.fieldContainer {
    display: inline-block;
    width: calc(50% - 4vw);
    padding-inline: 2vw;
    padding-bottom: 1vw;
}

.fieldContainer label {
    color: #403F41;
    /* font-family: Archivo Narrow; */
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.fieldContainer input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--Rectangle, #D9D9D9);
    height: 2vw;
    font-size: 1vw;
    padding-left: 1vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
}

.backgroundImage {
    height: 100%;
    background: #F5F6FA;
    text-align: center;
    position: relative;
    overflow: auto;
}

.backgroundImage > div {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    background: white;
}

.homeTitle {
    color: var(--secondary-color);
    font-size: 3vw;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    display: block;
}

.homeCard {
    border-radius: 1.5vw;
    background: var(--Blanco, #FFF);
    box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.10);
    display: inline-block;
    width: 20vw;
    height: 13vw;
    text-align: center;
    margin-right: 1vw;
    margin-bottom: 2vw;
}
.imageContainer {
    display: block;
    width: 100%;
    text-align: center;
}

.logoSize {
    width: 16vw !important;
    height: 6vw !important;
    cursor: pointer;
    margin-bottom: 1vw;
}
.stepperContainerForm {
    width: 100%;
    display: inline-block;
    height: 3vw;
}

.stepperContainer {
    display: inline-block;
    /* 
    height: 31vw;
    margin-top: 2vw; */
    text-align: left;
    background: white;
    padding: 3vw;
    border-radius: 1.3vw;
    /* box-shadow: 0px 0px 2vw 0px rgba(0, 0, 0, 0.10); */
    padding-top: 2vw;
    position: relative;
    padding-bottom: 0px;
}

.labelStep span {
    font-size: 1.2vw;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
}

.labelStep.active span {
    color: var(--primary-color)!important;
}

.labelStep span span.MuiStepLabel-alternativeLabel {
    margin-top: 1vw;
}

.stepperStep {
    height: 3vw;
    width: 3vw;
}

.stepperStep svg {
    height: 3vw;
    width: 3vw;
}

.stepperStep svg text {
    fill: var(--secondary-color);
    font-weight: 700;
}

.stepperStep svg circle {
    fill: none;
    stroke: var(--secondary-color);
    r: 11;
}

svg.stepComplete text {
    fill: white;
    font-weight: 700;
}

svg.stepComplete circle{
    fill: var(--primary-color);
    stroke: var(--primary-color);
}

svg.stepActive text {
    fill: var(--primary-color);
    font-weight: 700;
}

svg.stepActive circle{
    fill: none;
    stroke: var(--primary-color);
}

.stepperStep > div {
    top: 1.5vw;
    left: calc(-50% + 3vw);
    right: calc(50% + 3vw);
}

.stepperStep > div > span {
    border-block-width: 2px;
    border-color: #403F41;
}

.stepTitle {
    width: 100%;
    height: 3.2vw;
    display: inline-block;
    background: var(--primary-color);
    text-align: center;
    border-radius: 1vw;
}

.title {
    font-size: 2.1vw;
    margin-top: 0.4vw;
    display: inline-block;
    color: #FFF;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.iconTitle svg {
    position: absolute;
    left: 3vw;
    height: 3vw;
    width: 3.3vw;
    top: 0.2vw;
}

.iconTitle svg path {
    fill: white;
    stroke: white;
}

.backButtonMobile {
    display: none;
}

.backButton {
    width: 6vw;
    height: 3.3vw;
    display: inline-block;
    margin-right: 1vw;
    border-radius: 1vw;
    background: white;
    cursor: pointer;
    position: relative;
    bottom: 0.5vw;
}

.backButton svg {
    position: relative;
    bottom: 0.1vw;
    right: 0.5vw;
    width: 5vw;
    height: 3.5vw;
    color: var(--primary-color);
}

.headerStepper {
    width: 100%;
    display: block;
    text-align: right;
    height: 4vw;
}

.stepperLegend {
    color: #403F41;
    text-align: center;
    font-size: 1.4vw;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-top: 0;
}

.formButtonBack:disabled {
    border: 2px solid #D9D9D9;
    color: #D9D9D9;
}

.formButtonBack {
    border-radius: 0.8vw;
    border: 2px solid var(--primary-color);
    background: var(--Blanco, #FFF);
    color: var(--primary-color);
    width: 11vw;
    font-weight: 400;
    font-size: 1vw;
    margin-right: 3vw;
}

.formButtonNext {
    border-radius: 0.8vw;
    border: 2px solid var(--primary-color);
    color: var(--Blanco, #FFF);
    background: var(--primary-color);
    width: 11vw;
    font-weight: 400;
    font-size: 1vw;
}

.formButtonNext:hover {
    color: var(--Blanco, #FFF);
    background: var(--primary-color);
    opacity: .9;

}

.formContainer {
    display: inline-block;
    width: 90%;
    text-align: left;
    margin-left: 0.5vw;
    margin-top: 2vw;
}

.fieldContainer {
    display: inline-block;
    width: calc(50% - 4vw);
    padding-inline: 2vw;
    padding-bottom: 1vw;
}

.fieldContainer label {
    color: #403F41;
    /* font-family: Archivo Narrow; */
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.fieldContainer input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--Rectangle, #D9D9D9);
    height: 2vw;
    font-size: 1vw;
    padding-left: 1vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
}


.mobileContainer {
    display: none;
}


button.formButtonBack:disabled {
    border: 2px solid #D9D9D9;
    color: #D9D9D9;
}

button.formButtonBack {
    border-radius: 0.8vw;
    border: 2px solid var(--primary-color);
    background: var(--Blanco, #FFF);
    color: var(--primary-color);
    width: 11vw;
    font-size: 1vw;
    margin-right: 3vw;
}

button.formButtonNext {
    border-radius: 0.8vw;
    border: 2px solid var(--primary-color);
    color: var(--Blanco, #FFF);
    background: var(--primary-color);
    width: 11vw;
    font-size: 1vw;
}

button.formButtonNext:hover {
    color: var(--Blanco, #FFF);
    background: var(--primary-color);
    opacity: .9;

}

.stepperLegendMobile {
    display: none;
}


.stepTitle.alignStepTitle {
    bottom: .3vw;
}



@media (max-width: 900px) {

    .webContainer {
        display: none;
    }

    .mobileContainer {
        display: block;
    }

    .formContainer {
        width: 100%;
        text-align: center;
        margin-bottom: 5vw;
        margin-top: 5vw;
    }

    .fieldContainer {
        width: 90%;
        text-align: left;
        display: inline-block;
    }

    .fieldContainer label {
        font-size: 3vw;
    }

    .fieldContainer input {
        border-radius: 1vw;
        height: 6vw;
        font-size: 3vw;
        width: 96%;
    }

    .backButton {
        display: none;
    }

    .backButtonMobile {
        display: block;
        position: absolute;
        left: 4vw;
        top: 6vw;
    }

    .backButtonMobile svg {
        width: 7vw;
        height: 7vw;
    }

    .stepperContainer {
        display: inline-block;
        width: 85vw;
        height: auto;
        margin-top: 5vw;
        text-align: left;
        background: white;
        padding: 3vw;
        border-radius: 1.3vw;
        box-shadow: 0px 0px 2vw 0px rgba(0, 0, 0, 0.10);
        padding-top: 2vw;
        position: relative;
    }

    button.formButtonBack {
        display: inline-block;
        border-radius: 2vw;
        width: 90%;
        font-size: 3vw;
        margin-right: 0;
        margin-bottom: 3vw;
    }

    button.formButtonNext {
        display: inline-block;
        border-radius: 2vw;
        width: 90%;
        font-size: 3vw;
    }


.stepperStep {
    height: 7vw;
    width: 7vw;
}

.stepperStep svg {
    height: 7vw;
    width: 7vw;
}

.stepperStep svg text {
    fill: var(--secondary-color);
    font-weight: 700;
}

svg.stepComplete text {
    fill: white;
    font-weight: 700;
}

svg.stepComplete circle{
    fill: var(--primary-color);
    stroke: var(--primary-color);
}

svg.stepActive text {
    fill: var(--primary-color);
    font-weight: 700;
}

svg.stepActive circle{
    fill: none;
    stroke: var(--primary-color);
}

.stepperStep > div {
    top: 3vw;
    left: calc(-50% + 7.5vw);
    right: calc(50% + 7.5vw);
}

.labelStepText {
    display: none;
}

.stepperContainerForm{
    margin-top: 4vw;
}


.stepperContainerForm > div {
    width: 70%;
    margin-left: 15%;
}

.stepperStep > div > span {
    border-block-width: 1px;
}

.headerStepper {
    display: inline-block;
    width: 90%;
}

.stepTitle {
    width: 100%;
    height: 8vw;
    border-radius: 2.5vw;
    padding-top: 1.5vw;
    bottom: 0.3vw;
}

.title {
    font-size: 3.5vw;
    margin-top: 1vw;
    font-weight: 900;
}

.iconTitle svg {
    position: relative;
    height: 3vw;
    width: 4.3vw;
    top: 0.5vw;
    display: inline-block;
    margin-right: 2vw;
    left: 0;
}

.imageContainer {
    background-color: #FFF;
    display: inline-block;
    margin-bottom: 5vw;
}

.logoSize {
    width: 34vw !important;
    height: 14vw !important;
    margin-top: 4vw;
}

.stepperLegend {
    display: none;
}

.stepperLegendMobile {
    color: #403F41;
    text-align: center;
    font-size: 3.4vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
    display: inline-block;
    width: 55%;
    margin-bottom: 2.5vw;
}
/* 
.stepTitle.alignStepTitle {
    bottom: unset;
} */

}