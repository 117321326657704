.errorButton svg {
    width: 17px;
    bottom: 3px;
    position: relative;
    padding-right: 8px;
}

.errorButton svg path {
    fill: var(--primary-color);
}

.customElement > input {
    background-color: #f5f6fa;
    border-radius: 10px;
    border-width: 0;
    font-family: Gilroy-Medium;
    font-size: 13px;
    height: 31.5px;
    padding-left: 10px;
    width: calc(100% - 50px);
}

.reservedWithPendingDate{
    position: relative;
    left: 16px;
}

.textWithCopy{
    width: 160px;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
}

.textWithCopy > p{
    position: relative;
    bottom: 4px;
}

.checkButton {
    position: relative;
    top: 3px;
    height: 25px;
    display: inline-block;
    width: 25px;
    padding: 3px;
    margin-left: 4px;
    background: var(--primary-color);
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.createBtnIcon svg {
    position: relative;
    top: 8px;
}

.createBtnIcon svg path {
    fill: white;
}

.refreshIconCss {
    /* position: relative;
    bottom: 5px; */
    position: absolute;
    left: 0px;
}

.redRow {
    background-color: rgba(255, 194, 194, 0.888);
}

tr.simpleRow td > span {
    display: inline-flex !important;
    align-items: center;
}

tr.simpleRow td > span > span {
    min-width: 60px;
    text-align: center;
}


.reserveCellData {
    position: relative;
    bottom: 5px;
    margin-right: 8px;
}

.warningIcon {
    color: orange;
    cursor: pointer;
}

.buttonsContainer{
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    width: 115%;
    position: relative;
}

.productDescription {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    width: 150px;
}

button.buttonDeleteCancel{
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

button.buttonDeleteCancel:hover{
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    opacity: 70%;
}

button.buttonDelete{
    background-color: var(--primary-color);
    color: white;
}

button.buttonDelete:hover{
    background-color: var(--primary-color);
    color: white;
    opacity: 70%;
}

.stockDataTable{
    height: calc(100vh - 108px);
    max-width: 100%;
    overflow: hidden; 
    padding-bottom: 22px
}

@media (max-width: 1366px) {
    .stockDataTable{
      height: calc(125vh - 108px);
    }
}