button.verTodasButton{
    color: black;
    text-transform: none;
    font-weight: 400;
    border-top: ridge;
    width: 80%;
    font-size: 14px;
    align-content: center;
}

.buttonContainer{
    position: sticky;
    text-align: center;
    width: 100%;
    bottom: 0;
    background-color: white;
}

.refreshIconTitle svg {
    position: relative;
    cursor: pointer;
    fill: var(--primary-color);
    top: -12px;
}

button.button-newsan-red{
    color: white;
    background-color: var(--primary-color);
    border-radius: 10px;
    height: 38px;
    width: 40px;
}

button.button-newsan-red:hover{
    background-color: var(--primary-color);
}